import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import posts_data from '../../posts.json';
import MarkdownReader from '../../components/MarkdownReader';
import { useTheme } from '../../themeContext';
import Error404 from './../404/404';

const Post = ({ lang }) => {
  const { slug } = useParams();
  const { theme } = useTheme();
  const post = posts_data.find(p => p.slug === slug && p.lang === lang);

  useEffect(() => {
    const originalTitle = document.title; // Guarda el título original
    document.title = `M3 | ${slug.replace(/-/g, ' ')}`;

    return () => {
      document.title = originalTitle; // Restaura el título original al desmontar el componente
    };
  }, [slug]);

  if (!post) {
    return <Error404 />;
  }

  return (
    <>
      <div className={`bg ${theme}`}></div>
      <div className={`content ${theme}`}>
        <MarkdownReader markdownUrl={`md/projects/${lang}/${slug}.md`} />
      </div>
    </>
  );
};

export default Post;
