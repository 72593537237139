import React from 'react';
import { useTheme } from '../../themeContext';
import MarkdownReader from '../../components/MarkdownReader';

const About = ({ lang }) => {
  const { theme } = useTheme();

  const markdownUrl = lang === 'en' ? 'md/en_about.md' : 'md/about.md';

  return (
    <>
      <div className={`bg ${theme}`}></div>
      <div className={`content ${theme}`}>
        <MarkdownReader markdownUrl={markdownUrl} />
      </div>
    </>
  );
};

export default About;
