import React from 'react';
import { useTheme } from '../../themeContext';
import { useTranslation } from 'react-i18next';
import './404.css';

const Error404 = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <div className={`error-page ${theme}`}>
      <h1>404 - {t("page_not_found")}</h1>
      <p>{t("page_not_found_text")}</p>
      <img src="/satanic-pc.png" title="Just a normal satanic pc" className="logo" />
    
    </div>
  );
};

export default Error404;
