import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider } from './themeContext';
import NavigationBar from './components/NavigationBar';
import Home from './pages/home/Home';
import Error404 from './pages/404/404';
import Post from "./pages/post/Post";
import Posts from "./pages/posts/Posts";
import About from "./pages/about/About";
import './i18n';
import { useTranslation } from 'react-i18next';
import './App.css';


const AppWrapper = () => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const langFromUrl = searchParams.get('lang');
  const storedLang = localStorage.getItem('preferredLang') || 'es'; 

  const lang = langFromUrl || storedLang;

  useEffect(() => {
    if (langFromUrl && langFromUrl !== storedLang) {
      i18n.changeLanguage(langFromUrl)
      localStorage.setItem('preferredLang', langFromUrl);
    }
  }, [langFromUrl, storedLang]);

  return (
    <>
      <NavigationBar lang={lang} />
      <Routes>
        <Route path="/" element={<Home lang={lang} />} />
        <Route path="/about-me" element={<About lang={lang} />} />
        <Route path="/posts" element={<Posts lang={lang} />} />
        <Route path="/posts/:slug" element={<Post lang={lang} />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

function App() {
  const homepage = process.env.PUBLIC_URL;

  return (
    <ThemeProvider>
      <Router basename={homepage ? homepage : "/"}>
        <AppWrapper />
      </Router>
    </ThemeProvider>
  );
}

export default App;
