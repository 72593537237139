import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './NavigationBar.css'; // Asegúrate de tener este archivo CSS para los estilos
import { useTheme } from '../themeContext';
import { useTranslation } from 'react-i18next';

import esFlag from './flags/es.png'; // Ruta a la imagen de la bandera de España
import enFlag from './flags/en.png'; // Ruta a la imagen de la bandera de Inglaterra/EE.UU.

const NavigationBar = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();

  const currentLang = new URLSearchParams(location.search).get('lang') || 'es';

  const changeLanguage = () => {
    const newLang = currentLang === 'es' ? 'en' : 'es';
    i18n.changeLanguage(newLang);
    localStorage.setItem('preferredLang', newLang);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('lang', newLang);

    // Recargar la página con el nuevo idioma
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  return (
    <nav className={`navigation-bar ${theme}`}>
      <NavLink to="/" className="path blink">
        {`root@kali:${(location.pathname === '/') ? "~" : location.pathname + "/"}#`}$
      </NavLink>
      <div className="nav-links">
        <NavLink to="/" exact activeClassName="active">Home</NavLink>
        <NavLink to="/about-me" activeClassName="active">{t("about_me")}</NavLink>
        <NavLink to="/posts" activeClassName="active">Posts</NavLink>
        
        <button onClick={toggleTheme} className="theme-toggle">
          {theme === 'dark' ? '🌕' : '🌑'}
        </button>

        
        <button onClick={changeLanguage} className="language-toggle" style={{border:'none', display:'block',left:'0px', backgroundColor:'transparent'}}>
          <img 
            src={currentLang === 'es' ? enFlag : esFlag} 
            alt={currentLang === 'es' ? 'English' : 'Español'} 
            className="flag-icon" width='20px'
          />
        </button>

      </div>
    </nav>
  );
};

export default NavigationBar;
