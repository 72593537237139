import React from 'react';
import { FaTwitter, FaGithub, FaLinkedin } from 'react-icons/fa';
import './Home.css';
import { useTheme } from '../../themeContext';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const {t} = useTranslation();
  const { theme } = useTheme();

  return (
    <div className={`bg ${theme}`} style={{width:'100%',height:'100%', position:'fixed'}}>
      <div className={`content home ${theme}`}>
      <div className="home-content">
        <img src="https://avatars.githubusercontent.com/u/62236987" alt="Logo" className="logo" />
        <h1>M3str3</h1>
        <p className="description">{t("main_text")}</p>
        <div className="social-links">
          <a href="https://twitter.com/0xrootkit" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="https://github.com/m3str3" target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>
          <a href="https://www.linkedin.com/in/ignacio-josé-mestre-villagrasa-b79493183" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Home;
