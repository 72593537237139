import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import hljs from 'highlight.js';
import mermaid from 'mermaid';
import 'highlight.js/styles/github.css';
import './MarkdownReader.css';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../themeContext';

// Función para extraer el front-matter del markdown
const extractFrontMatter = (text) => {
    const frontMatterMatcher = /^---\s*[\r\n]([\s\S]+?)\s*---/;
    const titleMatcher = /^title:\s*(.+)$/m;
    const dateMatcher = /^date:\s*(.+)$/m;
    const modMatcher = /^modification:\s*(.+)$/m;
    const frontMatterMatch = text.match(frontMatterMatcher);
    let contentWithoutFrontMatter = text;
    let title = '';
    let date = '';
    let mod = '';

    if (frontMatterMatch) {
        const frontMatterContent = frontMatterMatch[1];
        const titleMatch = frontMatterContent.match(titleMatcher);
        const dateMatch = frontMatterContent.match(dateMatcher);
        const modMatch = frontMatterContent.match(modMatcher);
        contentWithoutFrontMatter = text.slice(frontMatterMatch[0].length);

        if (titleMatch) title = titleMatch[1].trim();
        if (dateMatch) date = dateMatch[1].trim();
        if (modMatch) mod = modMatch[1].trim();
    }

    return { contentWithoutFrontMatter, title, date, mod };
};

// Función para inicializar Mermaid
const initializeMermaid = (theme) => {
    mermaid.initialize({
        startOnLoad: true,
        theme: theme === 'light' ? 'neutral' : 'dark', // `forest`, `dark`, `neutral`, `base` etc.
        themeVariables: {
            fontSize: '12px',
            nodeSpacing: 50,
            edgeLength: 50,
            nodeWidth: 150,
            nodeHeight: 30,
        },
        flowchart: {
            useMaxWidth: false,
            htmlLabels: true,
        },
    });
};

// Función para ajustar el tamaño del gráfico de Mermaid
const resizeMermaidGraphs = () => {
    const svgElements = document.querySelectorAll('.mermaid svg');
    svgElements.forEach(svg => {
        const bbox = svg.getBBox();
        svg.setAttribute('viewBox', `0 0 ${bbox.width} ${bbox.height + 50}`);
        svg.setAttribute('width', '100%');
        svg.setAttribute('height', `${bbox.height + 50}`);
    });
};

// Función para añadir números de línea a los bloques de código
const addLineNumbers = (html, t) => {
    const codeBlockRegex = /<pre><code([^>]*)>([\s\S]*?)<\/code><\/pre>/g;
    const matches = [...html.matchAll(codeBlockRegex)];

    matches.forEach((match) => {
        const [fullMatch, attrs, code] = match;
        const lines = code.split('\n');
        if (lines[lines.length - 1] === '') lines.pop();

        const languageMatch = attrs.match(/class="([^"]*language-([^"\s]+)[^"]*)"/);
        const language = languageMatch ? languageMatch[2] : 'plaintext';

        const newCodeLines = lines.map((line, idx) =>
            `<span class="line-number">${idx + 1}</span><span class="code-line">${line}</span>\n`
        ).join('');

        const newCodeBlock = `<pre><div><span class='code-lang-name'>${language}</span><span class='copy-btn' onclick="copyCodeToClipboard(this)">${t("copy_code")}</span></div><hr><code${attrs}>${newCodeLines}</code></pre>`;

        html = html.replace(fullMatch, newCodeBlock);
    });

    return html;
};

// Componente principal MarkdownReader
const MarkdownReader = ({ markdownUrl }) => {
    const { theme } = useTheme();
    const { t } = useTranslation();
    const [markdown, setMarkdown] = useState('');
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [mod, setMod] = useState('');
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');
    const [modalImageTitle, setModalImageTitle] = useState('');

    const fullPath = process.env.PUBLIC_URL + '/' + markdownUrl;

    // Fetch del markdown y renderizado inicial
    useEffect(() => {
        fetch(fullPath)
            .then(response => {
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                return response.text();
            })
            .then(text => {
                const { contentWithoutFrontMatter, title, date, mod } = extractFrontMatter(text);
                setTitle(title);
                setDate(date);
                setMod(mod);

                const renderer = new marked.Renderer();
                renderer.code = (code, lang) => {
                    if (lang === 'mermaid') {
                        return `<div class="mermaid">${code}</div>`;
                    } else {
                        const highlighted = hljs.getLanguage(lang)
                            ? hljs.highlight(code, { language: lang }).value
                            : hljs.highlightAuto(code).value;
                        return `<pre><code class="hljs ${lang ? `language-${lang}` : ''}">${highlighted}</code></pre>`;
                    }
                };

                marked.setOptions({
                    renderer,
                    highlight: function (code, lang) {
                        return hljs.getLanguage(lang) ? hljs.highlight(code, { language: lang }).value : hljs.highlightAuto(code).value;
                    },
                    langPrefix: 'hljs ',
                });

                setMarkdown(marked(contentWithoutFrontMatter));
            })
            .catch(error => {
                console.error('Error fetching markdown:', error);
                setMarkdown('<p>Error loading post!</p>');
            });
    }, [fullPath]);

    useEffect(() => {
        if (markdown) {
            const markdownElement = document.querySelector('.markdown');
            if (markdownElement) {
                hljs.highlightAll();
                const htmlWithLineNumbers = addLineNumbers(markdownElement.innerHTML, t);
                markdownElement.innerHTML = htmlWithLineNumbers;

                initializeMermaid(theme);
                mermaid.run();
                setTimeout(() => {
                    resizeMermaidGraphs();
                }, 300);
            }
            const images = document.querySelectorAll('.markdown img');
            images.forEach(img => {
                img.addEventListener('click', () => {
                    setModalImageSrc(img.src);
                    setModalImageTitle(img.title);
                    setIsImageModalOpen(true);
                });

                const spinner = document.createElement('div');
                spinner.className = 'loader';
                img.style.display = 'none';
                img.parentNode.insertBefore(spinner, img);

                img.onload = () => {
                    img.style.display = '';
                    spinner.remove();
                };
            });
        }
    }, [markdown, theme, t]);

    const closeModal = () => {
        setIsImageModalOpen(false);
        setModalImageSrc('');
    };

    return (
        <div className={`container container-markdown`}>
            <div className='post-header'>
                <h1>{title.replace(/'/g, '')}</h1>
                <p style={{ textAlign: 'end' }}>{date ? `Date of publication: ${date}` : ''}<br />{mod ? `Date of modification: ${mod}` : ''}</p>
            </div>
            <article className='markdown' dangerouslySetInnerHTML={{ __html: markdown }} />
            {isImageModalOpen && (
                <div className="image-modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close-btn" onClick={closeModal}>&times;</span>
                        <img src={modalImageSrc} alt="Full screen" />
                        <center><span style={{ color: 'white' }}>{modalImageTitle}</span></center>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MarkdownReader;
