import React from 'react';
import posts_data from '../../posts.json';
import { Link } from 'react-router-dom';
import { useTheme } from '../../themeContext';
import './Posts.css';

const groupPostsByYear = (posts) => {
    return posts.reduce((groups, post) => {
        const year = new Date(post.date).getFullYear();
        if (!groups[year]) {
            groups[year] = [];
        }
        groups[year].push(post);
        return groups;
    }, {});
};

const Posts = ({ lang }) => {
    const { theme } = useTheme();
    const filteredPosts = posts_data.filter(post => post.lang === lang);
    const groupedPosts = groupPostsByYear(filteredPosts);

    return (
        <>
            <div className={`bg ${theme}`}></div>
            <div className={`content ${theme}`}>
                <div className='posts container'>
                    <h1>Posts</h1>
                    <hr></hr>
                    {Object.keys(groupedPosts).length > 0 ? (
                        <ul style={{ listStyle: 'none' }}>
                            {Object.keys(groupedPosts).sort((a, b) => b - a).map(year => (
                                <li key={year}>
                                    <h2>{year}</h2>
                                    <ul>
                                        {groupedPosts[year].map(post => (
                                            <li key={post.slug} className='post-entry'>
                                                <Link to={`/posts/${post.slug}`}>
                                                    <h3>{new Date(post.date).toLocaleDateString(lang, { month: 'numeric', day: 'numeric' })} | {post.title}</h3>
                                                    <p>
                                                        {post.description}<br />
                                                    </p>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div style={{ fontSize: '64px' }}>No posts available :(</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Posts;
